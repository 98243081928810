import classNames from 'classnames';
import { bool, func, number, object, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Avatar,
  BrandingIconCard,
  InlineTextButton,
  LinkedLogo,
  Menu,
  MenuContent,
  MenuItem,
  MenuLabel,
  NamedLink,
} from '../../../../components';
import { ACCOUNT_SETTINGS_PAGES } from '../../../../routing/routeConfiguration';
import { FormattedMessage, intlShape } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';
import TopbarSearchForm from '../TopbarSearchForm/TopbarSearchForm';
import CustomLinksMenu from './CustomLinksMenu/CustomLinksMenu';
import css from './TopbarDesktop.module.css';

/**
 * Component for the "Signup as Customer" link
 */
const SignupLinkCustomer = () => (
  <NamedLink
    name="SignupForUserTypePage"
    params={{ userType: 'customer' }}
    className={css.topbarLink}
  >
    <span className={css.topbarLinkLabel}>
      <FormattedMessage id="TopbarDesktop.signupCustomer" />
    </span>
  </NamedLink>
);
// console.log(userType, "userType");

/**
 * Component for the "Signup as Merchant" link
 */
const SignupLinkMerchant = () => (
  <NamedLink
    name="SignupForUserTypePage"
    params={{ userType: 'provider' }}
    className={css.topbarLink}
  >
    <span className={css.topbarLinkLabel}>
      <FormattedMessage id="TopbarDesktop.signupMerchant" />
    </span>
  </NamedLink>
);

/**
 * Component for the "Login" link
 */
const LoginLink = () => (
  <NamedLink name="LoginPage" className={css.signinButton}>
    <span className={css.signupLabel}>
      <FormattedMessage id="TopbarDesktop.login" />
    </span>
  </NamedLink>
);

/**
 * Component for the "Inbox" link with notification dot
 */
const InboxLink = ({ notificationCount, currentUserHasListings }) => {
  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  return (
    <NamedLink
      className={css.topbarLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.topbarLinkLabel}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  );
};

/**
 * Component for the profile menu with options for settings and logout
 */
const ProfileMenu = ({ currentPage, currentUser, onLogout }) => {
  /**
   * Determines the class name for the active menu item based on the current page
   */
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  return (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        {/* <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem> */}
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.menuLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  );
};

/**
 * The main topbar component for desktop layout
 */
const TopbarDesktop = ({
  className,
  config,
  customLinks,
  currentUser,
  currentPage,
  rootClassName,
  currentUserHasListings,
  notificationCount,
  intl,
  isAuthenticated,
  onLogout,
  onSearchSubmit,
  initialSearchFormValues,
}) => {
  const [mounted, setMounted] = useState(false);
  const userType = currentUser?.attributes?.profile?.publicData?.userType;

  useEffect(() => {
    setMounted(true);
  }, []);

  const marketplaceName = config.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  // Determine if the search form should take up space
  const giveSpaceForSearch = customLinks == null || customLinks?.length === 0;
  const classes = classNames(rootClassName || css.root, className);

  // Conditional rendering based on authentication status
  const inboxLinkMaybe = authenticatedOnClientSide ? (
    <InboxLink
      notificationCount={notificationCount}
      currentUserHasListings={currentUserHasListings}
    />
  ) : null;

  const profileMenuMaybe = authenticatedOnClientSide ? (
    <ProfileMenu currentPage={currentPage} currentUser={currentUser} onLogout={onLogout} />
  ) : null;

  const signupLinkCustomerMaybe = isAuthenticatedOrJustHydrated ? null : <SignupLinkCustomer />;
  const signupLinkMerchantMaybe = isAuthenticatedOrJustHydrated ? null : <SignupLinkMerchant />;
  const loginLinkMaybe = isAuthenticatedOrJustHydrated ? null : <LoginLink />;

  return (
    <nav className={classes}>
      <div>
        {userType === 'provider' || userType === undefined ? (
          <>
            {isAuthenticated ? (
              <div className={css.afterLoginWrapper}>
                <div className={css.loginWrapper}>
                  <div className={css.headerLeft}>
                    <div className={css.logoWrapper}>
                      <LinkedLogo
                        className={css.logoLink}
                        layout="desktop"
                        alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
                        linkToExternalSite={config?.topbar?.logoLink}
                      />
                    </div>
                    <div className={css.menuList}>
                      <div className={css.menuNames}>
                        <NamedLink className={css.link} name="LandingPage">
                          <span className={css.menuItemBorder} />
                          <FormattedMessage id="TopbarDesktop.home" />
                        </NamedLink>
                        <NamedLink className={css.link} name="LandingPage">
                          <span className={css.menuItemBorder} />
                          <FormattedMessage id="TopbarDesktop.bookings" />
                        </NamedLink>
                        <NamedLink
                          className={css.link}
                          name="ViewListingPage"
                          params={{ listingType: 'deal' }}
                        >
                          <span className={css.menuItemBorder} />
                          <FormattedMessage id="TopbarDesktop.deals" />
                        </NamedLink>
                        <NamedLink className={css.link} name="LandingPage">
                          <span className={css.menuItemBorder} />
                          <FormattedMessage id="TopbarDesktop.orders" />
                        </NamedLink>
                        <NamedLink
                          className={css.link}
                          name="ViewListingPage"
                          params={{ listingType: 'service' }}
                        >
                          <span className={css.menuItemBorder} />
                          <FormattedMessage id="TopbarDesktop.services" />
                        </NamedLink>
                        <NamedLink
                          className={css.link}
                          name="ViewListingPage"
                          params={{ listingType: 'product' }}
                        >
                          <span className={css.menuItemBorder} />
                          <FormattedMessage id="TopbarDesktop.products" />
                        </NamedLink>
                        <NamedLink className={css.link} name="MoreActionPage">
                          <span className={css.menuItemBorder} />
                          <FormattedMessage id="TopbarDesktop.more" />
                        </NamedLink>
                      </div>
                    </div>
                  </div>
                  <div className={css.headerRight}>
                    <div className={css.searchInput}>
                      <TopbarSearchForm
                        className={css.topbarSearch}
                        desktopInputRoot={css.topbarSearchWithLeftPadding}
                        onSubmit={onSearchSubmit}
                        initialValues={initialSearchFormValues}
                        appConfig={config}
                      />
                    </div>
                    <div className={css.settingIcon}>
                      <span>
                        <BrandingIconCard type="setting" />
                      </span>
                    </div>
                    <div className={css.notificationIcon}>
                      <span>
                        <BrandingIconCard type="notification" />
                      </span>
                    </div>
                    <div className={css.profileIcon}>{profileMenuMaybe}</div>
                  </div>
                </div>
              </div>
            ) : (
              <div className={css.headerWrapper}>
                <div className={css.leftBox}>
                  <div className={css.logoIcon}>
                    <LinkedLogo
                      className={css.logoLink}
                      layout="desktop"
                      alt={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
                      linkToExternalSite={config?.topbar?.logoLink}
                    />
                  </div>
                  {/* <TopbarSearchForm
       className={classNames(css.searchLink, { [css.takeAvailableSpace]: giveSpaceForSearch })}
       desktopInputRoot={css.topbarSearchWithLeftPadding}
       onSubmit={onSearchSubmit}
       initialValues={initialSearchFormValues}
       appConfig={config}
     /> */}
                  {/* <CustomLinksMenu
       currentPage={currentPage}
       customLinks={customLinks}
       intl={intl}
       hasClientSideContentReady={authenticatedOnClientSide || !isAuthenticatedOrJustHydrated}
     /> */}
                  <div className={css.centerHeader}>
                    <NamedLink className={css.link} name="LandingPage">
                      <span className={css.menuItemBorder} />
                      <FormattedMessage id="TopbarDesktop.home" />
                    </NamedLink>
                    <NamedLink className={css.link} name="LandingPage">
                      <span className={css.menuItemBorder} />
                      <FormattedMessage id="TopbarDesktop.forCustomers" />
                    </NamedLink>
                    <NamedLink className={css.link} name="LandingPage">
                      <span className={css.menuItemBorder} />
                      <FormattedMessage id="TopbarDesktop.merchants" />
                    </NamedLink>
                    <NamedLink className={css.link} name="LandingPage">
                      <span className={css.menuItemBorder} />
                      <FormattedMessage id="TopbarDesktop.features" />
                    </NamedLink>
                  </div>
                </div>
                {inboxLinkMaybe}

                {/* {signupLinkCustomerMaybe}
     {signupLinkMerchantMaybe} */}
                <div className={css.rightBarHeading}>
                  {loginLinkMaybe}
                  <NamedLink className={css.startFreeButton} name="SignupPage">
                    <span className={css.menuItemBorder} />
                    <FormattedMessage id="TopbarDesktop.startForFree" />
                  </NamedLink>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className={css.customerHeader}>
            <div className={css.customerWrapper}>
              <div className={css.customerLeftBox}>
                <NamedLink className={css.link} name="LandingPage">
                  <FormattedMessage id="TopbarDesktop.home" />
                </NamedLink>
                {/* <NamedLink className={css.link} name="LandingPage">
                  <FormattedMessage id="TopbarDesktop.bookings" />
                </NamedLink> */}
                <NamedLink
                  className={css.link}
                  name="AlgoliaSearchPage"
                  params={{ listingType: 'deals' }}
                >
                  <FormattedMessage id="TopbarDesktop.deals" />
                </NamedLink>
                <NamedLink
                  className={css.link}
                  name="AlgoliaSearchPage"
                  params={{ listingType: 'services' }}
                >
                  <FormattedMessage id="TopbarDesktop.services" />
                </NamedLink>
                <NamedLink className={css.link} name="LandingPage">
                  <FormattedMessage id="TopbarDesktop.about" />
                </NamedLink>
                <NamedLink
                  className={css.link}
                  name="SignupForUserTypePage"
                  params={{ userType: 'provider' }}
                >
                  <FormattedMessage id="TopbarDesktop.becomeMerchant" />
                </NamedLink>
              </div>
              <div className={css.customerCenterBox}>
                <NamedLink className={css.link} name="LandingPage">
                  <BrandingIconCard type="bluelogo" />
                </NamedLink>
              </div>
              <div className={css.customerRightBox}>
                <div className={css.userProfile}>{profileMenuMaybe}</div>
                <div className={css.customerLanguage}>
                  <span className={css.languageText}>Arabic</span>
                  <span className={css.languageText}>|</span>
                  <span className={css.languageText}>اللغة الانجليزية</span>
                </div>
                <div className={css.cartLogo}>
                  <BrandingIconCard type="cart" />
                  <span className={css.cartCount}>2</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

// Default prop values
TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  config: null,
};

// Prop type validation
TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  config: object,
};

export default TopbarDesktop;
